@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Geo&family=Nanum+Gothic&display=swap');

@font-face {
    font-family: 'TheJamsil5Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/TheJamsil5Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    clear: both;
    padding: 0;
    margin: 0;
    font-family: 'Nanum Gothic', '맑은고딕', 'Malgun Gothic', '돋움', Dotum, '굴림', Gulim, Helvetica, sans-serif;
    color: #333333;
    font-size: 18px;
    line-height: 1.2em;
    word-spacing: 0.1em;
    background: #fff;
    width: 100%;
    height: 100%;
    min-height: 100% !important;
}

h1, h2, h3, h4 {
    padding: 0;
    margin: 0;
}

ul, ul li, dl, dl dt, dl dd {
    list-style: none;
    margin: 0;
    padding: 0;
}

input {
    font-family: 'Nanum Gothic', '맑은고딕', 'Malgun Gothic', '돋움', Dotum, '굴림', Gulim, Helvetica, sans-serif;
    font-size: 0.8em;
}

input::-webkit-input-placeholder {
    color: #aaa;
}
input:-ms-input-placeholder {
    color: #aaa;
}
textarea::-webkit-input-placeholder {
    color: #aaa;
}
textarea:-ms-input-placeholder {
    color: #aaa;
}

textarea {
    font-family: 'Nanum Gothic', '맑은고딕', 'Malgun Gothic', '돋움', Dotum, '굴림', Gulim, Helvetica, sans-serif;
    font-size: 0.8em;
    resize: none;
}

hr {
    width: 100%;
    height: 0;
    border: 0 none;
    clear: both;
    margin: 0;
    padding: 0;
}

a {
    color: #333333;
    text-decoration: none;
}

button {
    border: none;
}

.App {
    text-align: center;
}

header h1 span {
    display: none;
}

header {
    font-family: 'GmarketSansMedium';
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 80px;
    line-height: 80px;
}

header.showMenu {
    background: #fff;
    border: 1px solid #dfdfdf;
}

header h1 img {
    width: 200px;
    height: 30px;
}

header a.logo {
    float: left;
}

#main_nav, #main_nav ul, #main_nav ul li {
    float: left;
}

#main_nav ul {
    margin-left: 50px;
}

#main_nav ul li {
    padding: 0 20px;
}

#main_nav ul li a {
    font-family: 'GmarketSansMedium';
    color: #fff;
    font-weight: bold;
}

header.showMenu #main_nav ul li a {
    color: #999;
}

#main_nav ul li a:hover, #main_nav ul li a:active, #main_nav ul li a.active {
    color: #225772 !important;
}

header.showMenu #main_nav ul li a:hover, header.showMenu #main_nav ul li a:active, header.showMenu #main_nav ul li a.active {
    color: #333 !important;
}

.w1200 {
    margin: 0 auto;
    padding: 100px 0;
    width: 1200px;
}

header .w1200 {
    padding: 0;
}

.section h2 {
    font-family: 'TheJamsil5Bold';
    color: #111;
    margin-bottom: 20px;
}

.section .section_info {
    color: #999;
}

.section .section_info strong {
    color: #0059ff;
}

#section1 {
    font-family: 'GmarketSansMedium';
    padding: 80px 0 0 0;
    background: #8dfdf6;
    background-image: linear-gradient(#8dfdf6, #83e9e3);
    min-height: 500px;
    text-align: left;
}

#section1 h2 {
    display: none;
}

#section1 .w1200 {
    position: relative;
    height: 330px;
}

#section1 .section1_info {
    position: absolute;
    left: 0;
    top: 100px;
    font-weight: bold;
    color: #fff;
    font-size: 1.5em;
    line-height: 1.4em;
    text-shadow: 2px 2px #69e2da;
}

#section1 .section1_info strong {
    color: #fcfe7c;
    font-size: 2em;
    display: block;
    padding: 30px 0;
}

#section1 .smartBox {
    width: 400px;
    height: 400px;
    float: right;
    margin-right: 40px;
}

#section2 ul {
    height: 400px;
    padding: 20px 0;
}

#section2 ul li {
    width: 25%;
    float: left;
    text-align: center;
}

#section2 ul li .inBox {
    margin: 15px;
    padding: 25px;
    border: 1px solid #eee;
    border-radius: 20px;
    height: 370px;
    box-shadow: 4px 4px #999;
    text-align: center;
}

#section2 ul li img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
}

#section2 ul li span {
    display: block;
    width: 100%;
    float: left;
}

#section2 ul li span.intitle {
    font-weight: bold;
    padding-bottom: 15px;
    font-family: 'TheJamsil5Bold';
    color: #0059ff;
}

#section2 ul li span.title {
    padding: 20px 0;
    font-family: 'TheJamsil5Bold';
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
}

#section2 ul li span.info {
    color: #999;
    text-align: center;
    font-weight: normal;
    font-size: 0.9em;
}

#section3 {
    background: #eee;
    height: 600px;
}

#section3 .w1200 {
    position: relative;
}

#section3 .smartBox {
    position: absolute;
    width: 360px;
    height: 360px;
    left: 20px;
    top: 150px;
}

#section3 ul {
    margin: 20px 20px 0 400px;
}

#section3 ul li {
    width: 50%;
    float: left;
}

#section3 ul li .inBox {
    margin: 15px 0 0 15px;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 4px 4px #999;
    padding: 15px;
    border-radius: 15px;
    text-align: left;
    height: 110px;
}

#section3 ul li .inBox h3 {
    color: #0059ff;
    font-family: 'TheJamsil5Bold';
}

#section3 ul li .inBox .title {
    display: block;
    padding: 10px 0;
    color: #111;
    font-family: 'TheJamsil5Bold';
}

#section3 ul li .inBox .info {
    font-size: 0.9em;
    color: #999;
}


#section4 .goForm {
    margin: 0 auto;
    margin-top: 30px;
    max-width: 500px;
}

#section4 .goForm label {
    display: none;
}

#section4 .goForm input {
    display: block;
    width: 100%;
    margin-top: 30px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding: 0 15px;
    color: #333;
    font-size: 0.9em;
    height: 40px;
    line-height: 40px;
}

#section4 .goForm textarea {
    display: block;
    width: 100%;
    margin-top: 30px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding: 10px 15px;
    color: #333;
    font-size: 0.9em;
    height: 100px;
}

#section4 button {
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
    background: #0059ff;
    display: inline-block;
    margin-top: 50px;
    width: 400px;
    line-height: 50px;
    height: 50px;
}

#section5 {
    background: #eee;
}

#section5 .w1200 {
    padding-bottom: 50px;
}

#section5 .setMap {
    border: 1px solid #999;
}

#section5 .section_info {
    display: block;
    margin-bottom: 30px;
}

footer {
    background: #333;
}

footer .w1200 {
    padding: 20px 0;
    font-size: 0.8em;
    color: #fff;
    text-align: left;
}

footer .w1200 .copy {
    margin-top: 40px;
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
}

footer .w1200 dl {
    display: block;
}

footer .w1200 dl dt, footer .w1200 dl dd {
    float: left;
    line-height: 1.7em;
}

footer .w1200 dl dt {
    margin: 0 10px;
}

footer .w1200 dl dd:nth-child(8) {
    float: unset;
}

@media (max-width: 512px) {
    .w1200 {
        width: auto;
    }

    #main_nav {
        display: none;
    }

    .section {
        padding: 30px 20px;
    }

    #section1 {
        min-height: 450px;
    }

    #section1 .section1_info {
        font-size: 0.7em;
        left: 20px;
        top: 10px;
        z-index: 3;
    }

    #section1 .smartBox {
        width: 250px;
        height: 250px;
        margin-top: 130px;
    }

    #section2 ul {
        margin: 0;
        padding: 0;
        height: auto;
    }

    #section2 ul li {
        display: block;
        width: auto;
        float: unset;
    }

    #section2 ul li .inBox {
        display: inline-block;
        width: 80%;
    }

    #section3 {
        height: auto;
    }

    #section3 .smartBox {
        display: inline-block;
        position: unset;
        left: 0;
        top: 0;
        width: auto;
    }

    #section3 ul {
        float: unset;
        margin: 0;
    }

    #section3 ul li {
        float: unset;
        padding: 0;
        margin: 0;
        width: auto;
    }

    #section3 ul li .inBox {
        margin-left: 0;
    }

    #section4 .goForm {
        max-width: unset;
    }

    #section4 .goForm input, #section4 .goForm textarea {
        display: block;
        width: 90%;
    }

    #section4 button {
        width: 80%;
    }

    footer .w1200 dl dd:nth-child(4), footer .w1200 dl dd:nth-child(6), footer .w1200 dl dd:nth-child(8), footer .w1200 dl dd:nth-child(10) {
        float: unset;
    }

    footer .w1200 dl dd:nth-child(12) {
        width: 80%;
    }
}
